import React from "react";

import { css } from "@emotion/css";
import Clickable from "../../../components/Clickable";
import buttons from "../../../styles/buttons";
import { laptop, mobile, tablet } from "../../../styles/breakpoints";
import { useEffect, useState } from "react";
import { client } from "../../../lib/client";

const Hero = ({homeText, homeImage}) =>{

  const [whitePaper, setWhitePaper] = useState([]);
  useEffect(() => {
    client.fetch(
      `*[_type == "header"] {
        title,
        file{
          asset ->{
            _id,
            url
          }
        },
      }`
    ).then((data) => {
      setWhitePaper(data);
    }).catch(console.error);
  },[])

  return (
    <>
      <div
        className={style.bg}
        style={{ backgroundImage: `url(${homeImage[0] && homeImage.filter(item => item.location === "background")[0].image.asset.url})` }}
      ></div>
      <div className={style.container}>
        <div className="wrapper">
          <h1>
            Hyper
            <br />
            Ring
            <img
              src={homeImage[0] && homeImage.filter(item => item.location === "sub logo")[0].image.asset.url}
              width="50"
              height="50"
              alt="Logo"
              className="logo"
            />
          </h1>
          <div className="content">
            <h3 style={{ whiteSpace: 'pre-line'}}>
              {homeText[0] && homeText.filter(item => item.type === "main description")[0].title}
            </h3>
            <p className="text" style={{ whiteSpace: 'pre-line'}}>
            {homeText[0] && homeText.filter(item => item.type === "main description")[0].content}
            </p>
            <Clickable className={buttons.primary} onClick={() => window.open(whitePaper[0] && whitePaper.filter(item => item.title === "whitepaper")[0].file.asset.url, '_blank')}>Pre-Order</Clickable>
          </div>
        </div>
      </div>
    </>
  );
}

const style = {
  bg: css({
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundSize: "auto",
    transition: "all .4s ease",
    width: "100%",
    height: 2080,
    opacity: 0.95,
    position: "absolute",
    left: 0,
    top: 0,
    zIndex: 0,
    backgroundColor: '#f5f5f5',

    [tablet]: {
    backgroundSize: "1800px",
    }
  }),
  container: css({
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignContent: "flex-start",
    flexWrap: "nowrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    maxWidth: 1830,
    margin: "auto",
    backgroundColor: '#f5f5f5',

    h1: {
      color: "#fff",
      fontFamily: "Halvar Breitschrift",
      fontSize: 215.088,
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: "-8.604px",
      lineHeight: '105%',
    },
    h3: {
      color: "#1B243A",
      fontSize: 27,
      fontStyle: "normal",
      fontWeight: 400,
      letterSpacing: 0.44,
      // textTransform: "uppercase",
      fontFamily: "Suisse Intl",
      marginBottom: 12,
      marginTop: 40,
    },

    ".logo": {
      marginBottom: 90,
    },

    ".text": {
      color: "rgba(27, 36, 58, 0.80)",
      fontFamily: "Suisse Intl",
      fontSize: 20,
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "28px",
      maxWidth: 800,
      letterSpacing: "-0.01em",
      textAlign: "left",
      marginBottom: 16,
    },

    ".wrapper": {
      maxWidth: 1830,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignContent: "flex-start",
      flexWrap: "nowrap",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      backgroundRepeat: "no-repeat",
      padding: 40,
      zIndex: 1,
    },

    ".content": {
      display: "flex",
      flexDirection: "column",
      alignContent: "flex-start",
      flexWrap: "nowrap",
      justifyContent: "space-around",
      alignItems: "flex-start",
      // paddingLeft: 120,
    },

    [laptop]: {
      h1: {
        fontSize: 140,
        letterSpacing: "-5.6px",
        marginBottom: 30
      },
      h3: {
        fontSize: 20,
        letterSpacing: 0.44,
      },

      ".wrapper": {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 80,
      },

      ".content": {
        // paddingLeft: 80,
      },

      ".text": {
        maxWidth: 300,
      },

      ".logo": {
        marginBottom: 50,
        marginLeft: 20,
        height: "40px",
        width: "40px",
      },
    },
    [tablet]: {
      h1: {
        fontSize: 100,
        letterSpacing: "-4px",
      },
      h3: {
        fontSize: 16,
        letterSpacing: 0.32,
      },

      ".wrapper": {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 80,
      },

      ".content": {
        // paddingLeft: 60,
      },

      ".text": {
        maxWidth: 200,
        fontSize: 14,
      },
      ".logo": {
        marginBottom: 35,
        marginLeft: 20,
        height: "30px",
        width: "30px",
      },
    },
    [mobile]: {
      h1: {
        fontSize: 68,
        letterSpacing: "-2.72px",
      },
      ".wrapper": {
        paddingLeft: 15,
        paddingRight: 15,
        paddingTop: 80,
      },

      ".content": {
        paddingLeft: 0,
      },
      ".logo": {
        marginBottom: 25,
        marginLeft: 15,
        height: "20px",
        width: "20px",
      },
    }
  }),
};

export default Hero;
